import { template as template_f45c607da7a44620923371d26edba79f } from "@ember/template-compiler";
const FKLabel = template_f45c607da7a44620923371d26edba79f(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
