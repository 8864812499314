import { template as template_5d8725fc565b4d98ac3a780eaeab1f15 } from "@ember/template-compiler";
const FKControlMenuContainer = template_5d8725fc565b4d98ac3a780eaeab1f15(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
